<template>
  <div
    class="section section--product bg-white"
    style="height: 100vh;"
  >
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col
          cols="3"
          class="d-flex align-items-center"
        >
          <div
            style="cursor: pointer;"
            @click="directPage"
          >
            <feather-icon
              size="24"
              class="text-dark font-weight-bolder"
              icon="ArrowLeftIcon"
            />
            <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
          </div>
        </b-col>
        <b-col
          cols="6"
          class="d-flex justify-content-center align-items-center"
        >
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            Hubungkan Produk
          </h1>
        </b-col>
        <b-col
          cols="3"
          class="text-right"
        >
          <b-button
            class="px-3"
            @click="createItem"
          >
            Simpan
          </b-button>
        </b-col>
      </b-row>
    </header>
    <b-container class="mt-5 pt-5">
      <b-row class="justify-content-center h-100 bg-white pb-5">
        <b-col
          cols="12"
          md="5"
        >
          <b-row>
            <!-- Kode SKU User -->
            <b-col
              cols="12"
              md="12"
              class="mb-2 custom__form--input"
            >
              <label for="v-kode-sku">Kode SKU User</label>
              <b-form-group>
                <b-form-input
                  class="custom__input"
                  :value="product.name"
                  disabled
                />
              </b-form-group>
              <small
                v-if="messages.merchant_product_uuid && Array.isArray(messages.merchant_product_uuid) && messages.merchant_product_uuid.length > 0"
                class="text-error fw-bold-500 size12"
              >{{ messages.merchant_product_uuid.join(', ') }}</small>
            </b-col>
            <!-- Nama Gudang -->
            <b-col
              cols="12"
              md="12"
              class="mb-2 custom__form--input"
            >
              <label>SKU Toqoo</label>
              <div class="d-flex align-items-center">
                <b-form-group class="w-100">
                  <v-select
                    v-model="formPayload.default_product_uuid"
                    label="name"
                    :options="optionsProduk"
                    placeholder="SKU Toqoo"
                    @search="onSearch"
                  />
                  <small
                    v-if="messages.default_product_uuid && Array.isArray(messages.default_product_uuid) && messages.default_product_uuid.length > 0"
                    class="text-error fw-bold-500 size12"
                  >{{ messages.default_product_uuid.join(', ') }}</small>
                </b-form-group>
                <div class="w-50 ml-1">
                  <b-button
                    variant="dark"
                    class="w-100"
                    @click="$bvModal.show('modal-produk')"
                  >
                    Tambah Produk
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <Modal />
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BContainer, BRow, BCol, BButton, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
import Modal from '@/components/Master/Produk/Modal.vue'

export default {
  components: {
    vSelect,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BContainer,
    Modal,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      formPayload: {
        merchant_product_uuid: '',
        default_product_uuid: '',
      },
      messages: '',
      photo_url: '',
      editUuid: null,
      optionsProduk: [],
      editId: '',
    }
  },
  computed: {
    ...mapState({
      products: state => state.produk.products,
      product: state => state.produk.product,
    }),
  },
  watch: {
    editId(value) {
      this.editUuid = value
    },
    formData(value) {
      this.formPayload = value
    },
    products: {
      handler(value) {
        this.optionsProduk = value.data
      },
      deep: true,
    },
  },
  created() {
    this.showProduk()
    this.getProduk()
  },
  methods: {
    ...mapActions('produk', ['getData', 'showData', 'postData']),
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, search, this)
      } else {
        loading(true)
        setTimeout(() => {
          this.optionsProduk = []
          this.getProduk()
          loading(false)
        }, 500)
      }
    },
    search: _.debounce((loading, search, vm) => {
      vm.getData({
        params: {
          product_type: 'default',
          search,
        },
      })
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.optionsProduk = response.data
          vm.$store.commit('produk/SET_PRODUCTS', response)
          loading(false)
        })
    }, 300),
    async getProduk() {
      const response = await this.getData({
        params: {
          product_type: 'default',
        },
      })
      this.optionsProduk = response.data
    },
    async showProduk() {
      await this.showData({
        id: this.$route.params.id,
        params: '',
      })
      this.formPayload.merchant_product_uuid = this.product.uuid
    },
    async createItem() {
      const form = this.preparePayload()
      this.isLoading = true
      const response = await this.postData({
        id: 'link',
        payload: form,
      })
      if (response) {
        this.isLoading = false
        this.$router.push({ name: 'master.product.index' })
        this.getProduk()
        successNotification(this, 'Success', 'Produk berhasil di hubungkan')
        this.cleanUpForm()
      }
    },
    cleanUpForm() {
      this.formPayload = {
        merchant_product_uuid: '',
        default_product_uuid: '',
      }
      this.messages = ''
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          if (key !== 'default_product_uuid') {
            form.append(key, this.formPayload[key])
          } else {
            form.append(key, this.formPayload[key].uuid)
          }
        }
      }
      return form
    },
    directPage() {
      this.cleanUpForm()
      this.$router.push({ name: 'master.product.index' })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/variables/_variables.scss';
</style>
